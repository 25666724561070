.bg-main-scope {
  background-color: #00428a !important;
}
.bg-sub-scope {
  background-color: #007aff !important;
}

.detail-section-scope-name-header-new-wrap-up {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding-top: 30px;
}

.detail-2-scope-name-header {
  font-family: Plus Jakarta Sans;
  font-size: 30px;
  font-weight: 700;
  line-height: 6.27px;
  letter-spacing: -0.01em;
  text-align: center;
}

.detail-2-sub-scope-name-header {
  font-family: Plus Jakarta Sans;
  font-size: 30px;
  font-weight: 700;
  line-height: 6.27px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #c7c7c7;
}

.detail-2-scale-text {
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 8.82px;
  letter-spacing: -0.01em;
  text-align: center;
}

.detail-2-scope-score-header {
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  height: 38px;
  width: 38px;
  font-style: normal;
  font-weight: 600;
  background: #f6c344;
  line-height: normal;
  border-radius: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.detail-2-sub-scope-score-header {
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  height: 38px;
  width: 38px;
  font-style: normal;
  font-weight: 600;
  border: 1px solid #f6c344;
  line-height: normal;
  border-radius: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
