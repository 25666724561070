
.jakarta-sans {
  font-family: Plus Jakarta Sans;
}

._detail-section-scope-name-header-pdf-new {
  color: #000;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  padding-left: 30px;
}

._detail-section-sub-scope-name-header-pdf-new {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  padding-left: 30px;
}


._main-scope-score-header-pdf-detail-new {
  color: #404040;
  text-align: center;
  font-size: 5mm;
  height: 36px;
  width: 36px;
  font-style: normal;
  font-weight: 600;
  background: #f6c344;
  line-height: normal;
  border-radius: 12mm;
  margin-left: 3mm;
}

._scope-detail-scope-container-new {
  width: 100%;
  background-color: #fff;
  padding: 0 20px;
  margin-top: 10px;
}

._scale-style-pdf-new {

  border-radius: var(--8, 8px);
  background: #eee;
  height: 20px;
  margin: 0 1mm;
  font-size: 8px;
  font-weight: 700;
  color: #adadad;
  font-style: normal;
  line-height: normal;
}

._hit-sub-scale-new {
  background: #007aff !important;
  color: #fff !important;
  border-radius: 2mm;
  font-weight: 700;
}

._hit-scale-new {
  background: #00428a !important;
  color: #fff !important;
  border-radius: 2mm;
  font-weight: 700;
}

._scale-detail-text-new {
  margin-top: 10px;
  color: #757575;
  text-align: justify;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 130% */

}

._scale-detail-text-pdf-new {
  margin-top: 3mm;
  color: #222222;
  text-align: justify;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}



._main-scope-name-pdf-new {
  color: #00428a;
  font-style: normal;
  height: 20px;
  margin: 0 1mm;
  font-size: 12px;
  font-weight: 800;
  line-height: normal;
  align-content: center;
}

._sub-scope-names-pdf-new {
  color: #4e4e4e;
  height: 20px;
  margin: 0 1mm;
  font-size: 10px;
  font-weight: 800;
  line-height: normal;
  align-content: center;
}

._bg-main-scope {
  background-color: #00428a !important;
}
._bg-sub-scope {
  background-color: #007aff !important;
}

._detail-section-scope-name-header-new-wrap-up {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding-top: 30px;
}

._detail-2-scope-name-header {
  font-family: Plus Jakarta Sans;
  font-size: 30px;
  font-weight: 700;
  line-height: 6.27px;
  letter-spacing: -0.01em;
  text-align: center;
}

._detail-2-sub-scope-name-header {
  font-family: Plus Jakarta Sans;
  font-size: 30px;
  font-weight: 700;
  line-height: 6.27px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #c7c7c7;
}

._detail-2-scale-text {
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 8.82px;
  letter-spacing: -0.01em;
  text-align: center;
}

._detail-2-scope-score-header {
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  height: 38px;
  width: 38px;
  font-style: normal;
  font-weight: 600;
  background: #f6c344;
  line-height: normal;
  border-radius: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

._detail-2-sub-scope-score-header {
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  height: 38px;
  width: 38px;
  font-style: normal;
  font-weight: 600;
  border: 1px solid #f6c344;
  line-height: normal;
  border-radius: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}


._main-scope-score-pdf-new {
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #f6c344;
  float: right;
  width: 20px;
  height: 20px;
  border-radius: 8mm;
}

._sub-scope-score-pdf-new {
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  float: right;
  border: 1px solid #f6c344;
  width: 20px;
  height: 20px;
  border-radius: 8mm;
}


body {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  overflow-x: hidden;
}

._flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

._overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  /*background-color: rgba(255, 255, 255, 0.6);*/
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  overflow-x: hidden;
}

._overlay-content {
  position: relative;
  top: 30%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #2a2a2a;
  font-weight: 400;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #2a2a2a;
  opacity: 1;
  font-weight: 400;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #2a2a2a;
  opacity: 1;
  font-weight: 400;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2a2a2a;
  font-weight: 400;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 400;
  color: #2a2a2a;
}

::placeholder {
  /* Most modern browsers support this now. */
  font-weight: 400;
  color: #2a2a2a;
}

._paginated-credits ul {
  display: flex;
}

._paginated-credits li {
  display: block;
  padding: 5px;
}

option {
  color: grey !important;
  font-family: Plus Jakarta Sans !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

option[disabled]:first-child {
  color: grey !important;
  font-family: Plus Jakarta Sans !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

._candidate-info-kvkk-modal-body h6 {
  color: #747474;
  font-size: 14px;
  font-weight: 800;
  line-height: 19.6px;
}

._result-page-result-id-h5 {
  color: #a4a4a4;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

._pdf-download-button {
  color: #000 !important;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 180px;
  height: 48px;
  flex-shrink: 0;
  background-color: #f6c344 !important;
  border: 1px solid #a4a4a4;
  border-radius: 30px;
  cursor: pointer;
}

._main-scope-name {
  color: #00428a;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

._main-scope-name-pdf {
  color: #00428a;
  font-family: Plus Jakarta Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

._main-scope-score-pdf {
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #f6c344;
  float: right;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
}

._main-scope-score-header-pdf-detail {
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  height: 55px;
  width: 55px;
  font-style: normal;
  font-weight: 600;
  background: #f6c344;
  line-height: normal;
  border-radius: 55px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

._sub-scope-score-pdf {
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  float: right;
  border: 1px solid #f6c344;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  margin-left: 10px;
}

._sub-scope-names-pdf {
  color: #4e4e4e;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}


._detail-section-scope-name-header-pdf {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

._detail-section-sub-scope-name-header-pdf {
  color: #b5b5b5;
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding-left: 30px;
  padding-top: 10px;
  margin-bottom: -8px;
}

._scale-detail-text {
  margin-top: 10px;
  color: #757575;
  text-align: justify;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.8px; /* 130% */
}

._scale-detail-text-pdf {
  margin-top: 10px;
  color: #222222;
  text-align: justify;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.8px; /* 130% */
}

._scope-detail-scope-container {
  width: 100%;
  background-color: #efefef;
  padding: 20px 130px;
  margin-bottom: 20px;
  border-radius: 20px;
}

._cover-page-candidate-data-container {
  position: absolute;
  top: 200mm;
  left: 30mm;
  height: 50mm;
  color: #5c5c5c;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

._cover-page-candidate-data-span {
  color: #5c5c5c;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  left: 40mm;
  white-space: nowrap;
}

._text-section {
  width: 100%;
  height: 100px;
  background: #f0f0ed;
  position: fixed;
  z-index: 99;
  top: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

._sidemenu-button svg {
  float: left;
  margin-top: 2px;
  margin-left: 6px;
}

._top-navigation-page-name {
  color: #00428a;
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 100px; /* 48px */
  padding-left: 40px;
}

@media (max-width: 425px) {
}

@media (min-width: 425px) {
}

._top-navigation-user-name {
  content: none !important;
  color: #00428a;
  font-family: Plus Jakarta Sans;
  position: absolute;
  right: 25px;
  line-height: 50px;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  border-radius: 10px;
  top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  background: #ffffff;
  border: 0;
  --bs-btn-active-bg: #dcdcdc;
  --bs-btn-active-color: #00428a;
}

._top-navigation-user-name:hover {
  color: #00428a;
  background: #efefef;
  cursor: pointer;
}

._project-page-excel-button-label :hover {
  cursor: pointer;
}

._project-page-excel-button-label span {
  padding-right: 5px;
}

._app-name-list-element {
  display: inline-flex;
  padding: 5px 10px;
  margin: 10px;
  color: #000;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 8px;
  background: #fff;
}

._clean-mail-list-button span {
  text-decoration-line: underline;
  margin-right: 5px;
}

._app-duration svg {
  margin-top: -4px;
}

/* ._ticket-result-table-row-container {
  border-radius: 12px;
  background: #fff;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
} */

._ticket-result-table-row-container h6 {
  margin-top: 3px;
  display: flex;
  align-items: center;
  height: 100%;
  color: #4e4e4e;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  line-height: 100%;
}

._ticket-result-table-row-container-header div {
  color: var(--secondary-500, #141522);
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 18px */
}

._thank-message {
  margin-top: 150px;
  width: 100%;
  text-align: center;
}

@media screen {
}

._put-in-front {
  position: absolute;
  top: 00px;
  left: 00px;
}

/* ###############################################################################################
###############################################################################################
###############################################################################################
###############################################################################################
############################################################################################### */
@media only screen and (max-width: 1200px) {

}

/* ###############################################################################################
###############################################################################################
###############################################################################################
###############################################################################################
############################################################################################### */

@media only screen and (max-width: 500px) {

  ._top-navigation-user-name {
    right: 25px;
    top: 25px;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
  }

  ._top-navigation-page-name {
    font-family: Plus Jakarta Sans;
    font-size: 22px;
    line-height: 100px; /* 48px */
    padding-left: 0;
  }

  ._main-scope-name {
    font-size: 14px;
  }

  ._result-page-result-id-h5 {
    font-size: 12px;
  }

}
