body {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  overflow-x: hidden;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  /*background-color: rgba(255, 255, 255, 0.6);*/
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  overflow-x: hidden;
}

.overlay-low-opacity {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  transition: 0.5s;
  overflow-x: hidden;
}

.overlay-content {
  position: relative;
  top: 30%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.polar-question-text {
  border-radius: 16px;
  background: #fff;
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 10px 30px;
  text-align: center;
}

.polar-btn-big {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin: 0 16px;
}

.polar-btn-mid {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin: 0 16px;
}

.polar-btn-small {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 16px;
}

.selected-answer-btn {
  color: #fff !important;
  background: rgb(40, 85, 172, 95%) !important;
}
.selected-answer-btn:hover {
  border: 1px solid white;
  font-size: 17px;
}

.answer-btn {
  height: 48px;
  border-radius: 16px;
  background: #fff;
  width: 100%;
  margin-bottom: 5px;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 1px solid #80808026;
  /* font-weight: bold; */
  color: #747474;
}

.answer-btn:hover {
  color: #fff;
  background: rgb(40, 85, 172, 95%) !important;
}
.answer-btn:focus {
  color: #fff;
  background: rgb(40, 85, 172, 95%) !important;
}

.answer-btn:active {
  color: #fff;
  background: rgb(40, 85, 172, 95%) !important;
}

.answer-btn:focus {
  outline: none !important;
  box-shadow: none !important;
  color: #747474 !important;
  background: #fff !important;
  /* background-color: transparent !important ; */
  border: 1px solid #80808026 !important;
}
.answer-btn:focus:not(:focus-visible) {
  outline: 0 !important;
  box-shadow: none !important;
  color: #747474 !important;
  background: #fff !important;
  border: 1px solid #80808026 !important;
}
.answer-btn::after {
  outline: 0 !important;
  box-shadow: none !important;
  color: #747474 !important;
  background: #fff !important;
  border: 1px solid #80808026 !important;
}
.answer-btn:active {
  outline: 0 !important;
  box-shadow: none !important;
  color: #747474 !important;
  background: #fff !important;
  border: 1px solid #80808026 !important;
}

.answer-btn:focus:hover {
  color: #fff !important;
  background: rgb(40, 85, 172, 95%) !important;
}
.answer-btn:focus:not(:focus-visible):hover {
  color: #fff !important;
  background: rgb(40, 85, 172, 95%) !important;
}
.answer-btn::after:hover {
  color: #fff !important;
  background: rgb(40, 85, 172, 95%) !important;
}
.answer-btn:active:hover {
  color: #fff !important;
  background: rgb(40, 85, 172, 95%) !important;
}

.answer-btn-question {
  height: 48px;
  border-radius: 16px;
  background: #fff;
  width: 100%;
  margin-bottom: 5px;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 1px solid #80808026;
  color: #747474;
}

.answer-btn-question:hover {
  color: #fff;
  background: rgb(40, 85, 172, 95%) !important;
}
.answer-btn-question:focus {
  color: #fff;
  background: rgb(40, 85, 172, 95%) !important;
}

.answer-btn-question:active {
  color: #fff;
  background: rgb(40, 85, 172, 95%) !important;
}

.answer-btn-question:focus {
  outline: none !important;
  box-shadow: none !important;
  color: #747474 !important;
  background: #fff !important;
  /* background-color: transparent !important ; */
  border: 1px solid #80808026 !important;
}
.answer-btn-question:focus:not(:focus-visible) {
  outline: 0 !important;
  box-shadow: none !important;
  color: #747474 !important;
  background: #fff !important;
  border: 1px solid #80808026 !important;
}
.answer-btn-question::after {
  outline: 0 !important;
  box-shadow: none !important;
  color: #747474 !important;
  background: #fff !important;
  border: 1px solid #80808026 !important;
}
.answer-btn-question:active {
  outline: 0 !important;
  box-shadow: none !important;
  color: #747474 !important;
  background: #fff !important;
  border: 1px solid #80808026 !important;
}

.answer-btn-question:focus:hover {
  color: #fff !important;
  background: rgb(40, 85, 172, 95%) !important;
}
.answer-btn-question:focus:not(:focus-visible):hover {
  color: #fff !important;
  background: rgb(40, 85, 172, 95%) !important;
}
.answer-btn-question::after:hover {
  color: #fff !important;
  background: rgb(40, 85, 172, 95%) !important;
}
.answer-btn-question:active:hover {
  color: #fff !important;
  background: rgb(40, 85, 172, 95%) !important;
}

.candidata-info-container-shadow {
  -webkit-box-shadow: 0 0 21px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 21px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 21px -4px rgba(0, 0, 0, 0.75);
  background: rgb(44, 48, 52);
  color: white;
  font-weight: bold;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #2a2a2a;
  font-weight: 400;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #2a2a2a;
  opacity: 1;
  font-weight: 400;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #2a2a2a;
  opacity: 1;
  font-weight: 400;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2a2a2a;
  font-weight: 400;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 400;
  color: #2a2a2a;
}

::placeholder {
  /* Most modern browsers support this now. */
  font-weight: 400;
  color: #2a2a2a;
}

.DateTimePicker {
  background: white;
}

.invalid {
  box-shadow: 0 0 8px 3px rgba(255, 0, 0, 0.72);
  -webkit-box-shadow: 0 0 8px 3px rgba(255, 0, 0, 0.72);
  -moz-box-shadow: 0 0 8px 3px rgba(255, 0, 0, 0.72);
}
.invalid-mail-input:focus {
  box-shadow: 0 0 4px 3px rgba(255, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 4px 3px rgba(255, 0, 0, 0.5);
  -moz-box-shadow: 0 0 4px 3px rgba(255, 0, 0, 0.5);
}
.valid-mail-input:focus {
  box-shadow: 0 0 8px 3px rgba(0, 255, 0, 0.5);
  -webkit-box-shadow: 0 0 8px 3px rgba(0, 255, 0, 0.5);
  -moz-box-shadow: 0 0 8px 3px rgba(0, 255, 0, 0.5);
}
.invalid-mail-input {
  box-shadow: 0 0 4px 3px rgba(255, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 4px 3px rgba(255, 0, 0, 0.5);
  -moz-box-shadow: 0 0 4px 3px rgba(255, 0, 0, 0.5);
}
.valid-mail-input {
  box-shadow: 0 0 8px 3px rgba(0, 255, 0, 0.5);
  -webkit-box-shadow: 0 0 8px 3px rgba(0, 255, 0, 0.5);
  -moz-box-shadow: 0 0 8px 3px rgba(0, 255, 0, 0.5);
}

.invalid-text {
  color: red;
}

.scale-delete-button {
  padding: 5px;
  margin: 3px;
  border-radius: 4px;
  color: red;
  border: 1px solid red;
}

.scale-values-card {
  border: 1px solid grey;
  padding: 5px;
  margin: 3px;
  border-radius: 4px;
}

.paginated-credits ul {
  display: flex;
}

.paginated-credits li {
  display: block;
  padding: 5px;
}

.hit-scale {
  background: #00428a !important;
  color: #fff !important;
  border-radius: 7px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 600;
  /* padding: 0 3px;
  margin: 1px 4px; */
}
.consistency-hit-scale {
  background: #894b98 !important;
  color: #fff !important;
  border-radius: 7px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 600;
  /* padding: 0 3px;
  margin: 1px 4px; */
}

.hit-sub-scale {
  background: #007aff !important;
  color: #fff !important;
  border-radius: 7px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 600;
  /* padding: 0 3px;
  margin: 1px 4px; */
}

.scale-style {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--8, 8px);
  background: #eee;
  height: 30px;
  margin: 0 10px;
  color: #adadad;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.scale-style-pdf {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--8, 8px);
  background: #eee;
  height: 30px;
  margin: 0 10px;
  color: #adadad;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.candidate-info-input-label {
  color: #303030;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

option {
  color: grey !important;
  font-family: Plus Jakarta Sans !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

option[disabled]:first-child {
  color: grey !important;
  font-family: Plus Jakarta Sans !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.candidate-info-kvkk-modal-body {
  color: #747474;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.6px; /* 140% */
}

.candidate-info-kvkk-modal-body h6 {
  color: #747474;
  font-size: 14px;
  font-weight: 800;
  line-height: 19.6px;
}

.candidate-info-kvkk-modal-closebutton {
  height: 48px;
  border-radius: 24px;
  border: 1px solid #c7c7c7;
  background: #fafaf9;
  width: 100%;
  color: #000;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.result-page-summary-header-h2 {
  color: #000;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.result-page-summary-header-col {
  height: 62px;
  border-radius: 16px;
  background: #f0f0ed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.result-page-summary-result-id-col {
  height: 62px;
  display: flex;
  align-items: center;
}

.result-page-result-id-h5 {
  color: #a4a4a4;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.result-page-summary-download-pdf-button-col {
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pdf-download-button {
  color: #000 !important;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 180px;
  height: 48px;
  flex-shrink: 0;
  background-color: #f6c344 !important;
  border: 1px solid #a4a4a4;
  border-radius: 30px;
}

.main-scopes-container {
  border-radius: 16px;
  background: #fff;
  padding: 16px;
}

.main-scope-name {
  color: #00428a;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.main-scope-name-pdf {
  color: #00428a;
  font-family: Plus Jakarta Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sub-scope-name {
  color: #4e4e4e;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.main-scope-score {
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #f6c344;
  float: right;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}
.main-scope-score-pdf {
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #f6c344;
  float: right;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
}
.main-scope-score-header-pdf {
  color: #404040;
  background: #f6c344;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px;
  margin-left: 20px;
  border-radius: 50%;
}
.main-scope-score-header-pdf-detail {
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  height: 55px;
  width: 55px;
  font-style: normal;
  font-weight: 600;
  background: #f6c344;
  line-height: normal;
  border-radius: 55px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.main-scope-score-header {
  color: #7a7a7a;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #f6c344;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  margin-left: 10px;
}

.sub-scope-score {
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  float: right;
  border: 1px solid #f6c344;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}

.sub-scope-score-pdf {
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  float: right;
  border: 1px solid #f6c344;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
}
.detail-sub-scope-score {
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 1px solid #f6c344;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin-left: 10px;
}
.detail-sub-scope-score-pdf {
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 1px solid #f6c344;
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 37px;
  margin-left: 10px;
  margin-top: -10px;
}

.subscope-header-scope-name {
  color: #303030;
  font-family: Plus Jakarta Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  display: flex;
  align-items: center;
}

.sub-scope-summary-container {
  border-radius: 16px;
  background: #fff;
}

.sub-scope-names {
  color: #4e4e4e;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.sub-scope-names-pdf {
  color: #4e4e4e;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.darken-hover {
  transition: background-color 0.3s ease;
}

.darken-hover:hover {
  background-color: rgba(112, 112, 112, 0.18); /* Adjust the background color as needed */
}

.round-left-side-30px {
  border-radius: 30px 0 0 30px;
}

.round-right-side-30px {
  border-radius: 0 30px 30px 0;
}

.round-30px {
  border-radius: 30px 30px 30px 30px;
}

.detail-sub-scope-names {
  color: #303030;
  font-family: Plus Jakarta Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 106%;
  display: flex;
}

.detail-scope-names {
  color: #c7c7c7;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 106%; /* 14.84px */
}

.b2c-detail-report-header-h2 {
  color: #000;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 62px;
  background: #f0f0ed;
  height: 62px;
  width: 410px;
  border-radius: 16px;
}

.b2c-detail-report-header-container {
  margin-top: 120px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scope-detail-container {
  margin-bottom: 100px;
  padding-bottom: 20px;
  border-left: 4px solid #5f5f5f;
  border-bottom: 4px solid #5f5f5f;
  position: relative;
  border-radius: 30px 0 0 30px;
}
.detail-container-pdf {
  background-color: #f7f7f4;
}

.scale-detail-container-top-border {
  border-top: 4px solid #5f5f5f;
  border-left: 4px solid #5f5f5f;
  border-radius: 30px 0 0 0;
  width: 40%;
  height: 10%;
  position: absolute;
  left: -4px;
  top: 0;
}

.dot-left-up {
  height: 26px;
  width: 26px;
  background-color: #5f5f5f;
  border-radius: 50%;
  position: absolute;
  left: 39%;
  top: -12px;
}

.dot-right-down {
  height: 26px;
  width: 26px;
  background-color: #5f5f5f;
  border-radius: 50%;
  position: absolute;
  right: -9px;
  bottom: -13px;
}

.detail-section-scope-name-header {
  color: #5f5f5f;
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 43%;
  top: -20px;
}
.detail-section-scope-name-header-pdf {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding-top: 30px;
}

.b2c-detail-scope-scale-container {
  border-radius: 16px;
  background: #fff;
  padding: 20px 20px 20px 20px;
  margin: 15px 15px 35px;
}

.scale-detail-text {
  margin-top: 10px;
  color: #757575;
  text-align: justify;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.8px; /* 130% */
}
.scale-detail-text-pdf {
  margin-top: 10px;
  color: #222222;
  text-align: justify;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.8px; /* 130% */
}

.summary-report-pdf-header {
  color: #8c8c8c;
  padding: 20px 0;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.detail-report-pdf-header {
  color: #8c8c8c;
  position: absolute;
  left: 40px;
  top: 20px;
  text-align: start;
  font-family: Plus Jakarta Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.detail-report-pdf-candidate-name {
  color: #8c8c8c;
  position: absolute;
  right: 40px;
  top: 20px;
  text-align: start;
  font-family: Plus Jakarta Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.summary-report-pdf-scope-container {
  border-radius: 10px;
  background: #fff;
  margin: 1px 30px 30px 30px;
  padding: 3mm;
}

.summary-report-pdf-subscope-header {
  color: #8c8c8c;
  font-family: Plus Jakarta Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border-radius: 10px;
  padding: 3px 35px;
  margin-top: 15px;
}

.scope-detail-scope-container {
  width: 100%;
  background-color: #efefef;
  padding: 20px 130px;
  margin-bottom: 20px;
  border-radius: 20px;
}
.subscope-detail-scope-container {
  margin: 20px 130px;
  border-radius: 20px;
}
.subscope-detail-sub-container-pdf {
  border-radius: 20px;
  background: #fff;
  padding: 20px;
  margin-top: 10px;
}

.cover-page-container {
  background: #f7f7f4;
}
.cover-page-blue-stripe {
  position: absolute;
  width: 36mm;
  background: #00428a;
  left: 25mm;
}

.cover-page-blue-stripe-top {
  top: 0;
  height: 30mm;
  border-radius: 0 0 2mm 2mm;
}

.cover-page-logo-name {
  position: absolute;
  left: 25mm;
  top: 33mm;
  color: #5c5c5c;
  font-family: Plus Jakarta Sans;
  font-size: 68px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cover-page-logo-test-name {
  position: absolute;
  left: 25mm;
  top: 54mm;
  color: #5c5c5c;
  font-family: Plus Jakarta Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cover-page-blue-stripe-middle {
  top: 75mm;
  height: 115mm;
  border-radius: 2mm;
}

.cover-page-blue-stripe-bottom {
  top: 270mm;
  height: 297mm;
  border-radius: 2mm 2mm 0 0;
}

.cover-page-candidate-data-container {
  position: absolute;
  top: 200mm;
  left: 30mm;
  height: 50mm;
  color: #5c5c5c;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
.cover-page-candidate-data-span {
  color: #5c5c5c;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  left: 40mm;
  white-space: nowrap;
}
.test-question-no-at-heder {
  text-align: center;
  z-index: 9999;
  position: fixed;
  top: 40px;
  width: 100%;
  color: #a4a4a4;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.test-question-progression-container {
  position: fixed;
  top: 70px;
  width: 100%;
  height: 8px;
  position: absolute;
  z-index: 9999;
  background: rgb(40, 85, 172);
}
.test-question-progression-bar {
  height: 8px;
  background: #f6c344;
}

.likert-image-question-container {
  position: fixed;
  width: 100%;
  min-height: 120px;
  background: #f0f0ed;
  top: -177px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #80808026;
}
.question-image-question-container {
  width: 100%;
  margin-top: 80px;
  min-height: 435px;
  background: #f0f0ed;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #80808026;
}

.likert-image-question-text {
  text-align: center;
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  padding: 0% 30%;
}

.text-section {
  width: 100%;
  height: 100px;
  background: #f0f0ed;
  position: fixed;
  z-index: 99;
  top: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.footer-prev-button {
  border-radius: 24px;
  border: 2px solid #c7c7c7;
  background: #fafaf9;
  width: 180px;
  height: 48px;
  flex-shrink: 0;
  color: #303030;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  float: right;
}
.footer-next-button {
  border-radius: 24px;
  background: #f6c344;
  color: #303030;
  width: 180px;
  height: 48px;
  flex-shrink: 0;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  float: left;
}
.polar-image-info-text {
  border: 1px solid #80808026 !important;
  color: #747474 !important;
  font-size: 18px !important;
  top: -197px !important;
  height: 120px;
}
.checkbox-image-info-text {
  font-size: 25px !important;
  border: 1px solid #80808026 !important;
  color: grey;
}
.form-check-input[type="checkbox"] {
  flex-shrink: 0 !important;
}

.created-correct-answer {
  border-radius: 5px;
  color: white;
  margin: 5px;
  padding: 2px;
  background-color: green;
}

.created-false-answer {
  margin: 5px;
  border-radius: 5px;
  padding: 2px;
  background-color: gainsboro;
}

.breadcrumb-container {
  margin-top: 30px;
}
.side-menu-container {
  position: fixed;
  width: 250px;
  left: 0;
  height: 100%;
  background: #fff;
  z-index: 9;
}
.top-navigation-container {
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100px;
  background: #fff;
}
.top-navigation-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
}
.sidemenu-button-container {
  position: fixed;
  top: 120px;
}
.sidemenu-button {
  display: flex;
  margin: 20px;
  padding: 10px;
  color: #8a8a8a;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  max-width: 210px;
}
.sidemenu-button:hover {
  background: #efefef;
}
.sidemenu-selected-button {
  background: #efefef;
  color: #00428a;
}

.sidemenu-button svg {
  float: left;
  margin-top: 2px;
  margin-left: 6px;
}

.language-button {
  background-color: #efefef;
  text-align: center;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  --bs-btn-hover-bg: #afafaf;
}

.top-navigation-page-name {
  color: #00428a;
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 100px; /* 48px */
  padding-left: 40px;
}

.breadcrumb-page-name {
  color: #00428a;
  font-family: Plus Jakarta Sans;
  font-size: 24px;
}

.top-navigation-user-name {
  content: none !important;
  color: #00428a;
  font-family: Plus Jakarta Sans;
  position: absolute;
  right: 25px;
  line-height: 50px;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  border-radius: 10px;
  top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  background: #ffffff;
  border: 0;
  --bs-btn-active-bg: #dcdcdc;
  --bs-btn-active-color: #00428a;
}

.top-navigation-user-name:hover {
  color: #00428a;
  background: #efefef;
  cursor: pointer;
}

.top-navigation-dropdown-menu {
  --bs-dropdown-link-color: #00428a;
  --bs-dropdown-link-active-bg: #dcdcdc;
  --bs-dropdown-link-active-color: #00428a;
  --bs-dropdown-link-hover-color: #00428a;
  --bs-dropdown-item-padding-x: 10px;
  --bs-dropdown-item-padding-y: 0px;
  --bs-btn-active-bg: #dcdcdc;
  --bs-btn-active-color: #00428a;
}

.page-container {
  position: absolute;
  left: 250px;
  top: 60px;

  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

.inner-page-container {
  padding: 20px 30px;
}
.sidemenu-button-text {
  margin-left: 35px;
}

.projects-page-container {
  height: 100%;
  width: 100%;
}
.project-page-tabs-container {
  margin: 40px 20px 20px 20px;
  width: -webkit-fill-available;
}
.project-page-tabs {
  background: #fff;
}

.project-page-tab-button {
  height: 50px;
  padding-top: 20px;
  width: 135px;
  line-height: 100%;
  display: inline-block;
  color: #141522;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  gap: 12px;
  margin: 0 5px;
}
.clicked-project-page-tab-button {
  border-bottom: 3px solid #00428a;
}

.project-page-component-container {
  margin: 20px;
  gap: -0.5px;
  width: 95%;
}
.project-page-input-label {
  margin-left: 5px;
  color: #303030;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.project-page-excel-button-label {
  color: #00428a;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.6px; /* 130% */
  text-decoration-line: underline;
  float: right;
}
.project-page-excel-button-label :hover {
  cursor: pointer;
}

.project-page-excel-button-label span {
  padding-right: 5px;
}
.send-ticket-mail-list-container {
  border-radius: var(--8, 8px);
  border: 1px solid #e4e4e4;
  max-height: 50vh;
  min-height: 20vh;
  height: 340%;
  margin-bottom: 20px;
  margin-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.send-confirm-list-container {
  border-radius: var(--8, 8px);
  max-height: 10vh;
  margin-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.send-ticket-mail-checkbox-label {
  padding-left: 15px;
  color: #303030;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.fav-button-container {
  display: flex;
  align-items: center;
  margin-left: -10px;
}
.fav-button-container:hover {
  cursor: pointer;
}
.show-cursor:hover {
  cursor: pointer;
}

.candidate-mail {
  display: inline-flex;
  padding: 5px 10px;
  margin: 10px;
  color: #000;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 8px;
  background: #fff;
}
.app-name-list-element {
  display: inline-flex;
  padding: 5px 10px;
  margin: 10px;
  color: #000;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 8px;
  background: #fff;
}
#admin-excel-input[type="file"] {
  display: none;
}
.clean-mail-list-button {
  color: #cd0000;
  text-align: right;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.6px; /* 130% */
}
.clean-mail-list-button span {
  text-decoration-line: underline;
  margin-right: 5px;
}
.clean-mail-list-button:hover {
  cursor: pointer;
}

.favourite-app-button {
  padding: 5px;
  margin: 5px;
  color: #000;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px;
}

.favourite-app-button:hover {
  cursor: pointer;
  background: #fff;
}

.app-card-container {
  margin: 20px;
  width: 320px;
  height: 340px;
  border-radius: var(--8, 8px);
  background: #fff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
}
.app-card-container:hover {
  box-shadow: 8px 11px 6px -7px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 8px 11px 6px -7px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 8px 11px 6px -7px rgba(0, 0, 0, 0.29);
}
.app-card-body {
  height: 125px;
}
.start-app-button {
  display: flex;
  width: 100%;
  height: 36px;
  padding: 14px 16px;
  justify-content: center;
  align-items: center;
  gap: var(--4, 4px);
  flex-shrink: 0;
  border-radius: var(--8, 8px);
  border: 1px solid #ffc900;
  color: #00428a;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: rgb(249, 249, 249);
}

.start-app-button:hover {
  /* border: 2px solid #ffc900; */
  background: white;
}
.app-card-text-label {
  color: #c1c1c1;
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 180% */
}
.app-card-text {
  margin-top: -13px;
  color: #355585;
  font-feature-settings:
    "cv11" on,
    "cv01" on,
    "ss01" on;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
}

.company-logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  background: white;
  padding-left: 0;
  padding-right: 0;
}
.start-app-button-container {
  position: relative;
}

.start-app-button-row {
  position: relative;
  bottom: 0;
}

.text-right {
  text-align: right;
  font-size: 12px !important;
}

.appilcation-card {
  border: 0 !important;
}
.app-card-col {
  padding: 0 !important;
}
.app-duration {
  color: #a4a4a4;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.app-duration svg {
  margin-top: -4px;
}
.app-duration-container {
  display: flex;
  align-items: center;
}
.card-footer {
  background: white;
  border: 0;
}

.ticket-result-filter-checkbox-label {
  padding-right: 5px;
  padding-left: 5px;
  color: #303030;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* .ticket-result-table-row-container {
  border-radius: 12px;
  background: #fff;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
} */

.ticket-result-table-row-container h6 {
  margin-top: 3px;
  display: flex;
  align-items: center;
  height: 100%;
  color: #4e4e4e;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  line-height: 100%;
}

.ticket-result-table-row-container-header {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
}

.ticket-result-table-row-container-header div {
  color: var(--secondary-500, #141522);
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 18px */
}
.ticket-result-table-row-container-body {
  overflow-y: auto;
  overflow-x: hidden;
}
.open-result-new-tab-icon-col:hover {
  cursor: pointer;
}

.time-stamp {
  font-size: 12px;
  margin-top: -15px;
  margin-left: 3px;
}

.login-page-container {
  margin-top: 120px;
}

.login-page-info-h5 {
  text-align: center;
  color: #00428a;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
}
.login-page-welcome-header {
  color: #ffc900;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
}

.login-page-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot-password-button {
  color: #00428a;
}
.forgot-password-button:hover {
  cursor: pointer;
  color: blue;
}

.orders-record-container {
  margin: 20px 0;
  padding: 10px;
  background: white;
  border-radius: 10px;
  color: #4e4e4e;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
}
.orders-record-container-header {
  padding: 0 15px;
  font-weight: 700;
  font-family: Plus Jakarta Sans;
}

.admin-settings-input-header {
  color: #2d3748;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.24px;
}

.admin-setting-page-add-image-label {
  display: flex;
  color: #00428a;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.6px;
  text-decoration-line: underline;
  align-items: center;
  justify-content: center;
}
.admin-setting-page-add-image-label:hover {
  cursor: pointer;
}
.logo-previev-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.counter-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 10%;
  color: white;
  font-size: 24px;
  font-weight: 600;
  margin: 5px;
  padding: 5px;
  display: flex;
}
.counter-container-danger {
  background-color: white;
  color: rgb(255, 72, 72) !important;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 10%;
  font-size: 24px;
  font-weight: 600;
  margin: 5px;
  padding: 5px;
  display: flex;
  border-radius: 15px;
}

.timer-second-minute-container {
  width: 50px;
  text-align: center;
}
.ticket-result-photos-container {
}

.ticket-result-photos-container:hover {
  cursor: pointer;
}

.candidate-photo {
  padding: 7px;
}

.thank-message {
  margin-top: 150px;
  width: 100%;
  text-align: center;
}

.save-and-exit-button {
  position: absolute;
  top: 100px;
  right: 20px;
  z-index: 99999999999999999;
  border-radius: 24px;
}

@media screen {
  .onlyPrint {
    display: none;
  }
}

.put-in-front {
  position: absolute;
  top: 0;
  left: 0;
}

.applications-container-row {
  padding: 0 20px;
  width: 100%;
  height: 40vh;
  overflow: auto;
}
.applications-container {
}
.statistics-notifications-container-row {
  padding: 20px;
  width: 100%;
  height: calc(50vh - 100px);
}
.admin-dash-headings {
  color: #00428a;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
}
.admin-dash-app-card {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.13));
  background: #00428a;
  color: white;
  width: 220px;
  height: 195px;
  margin: 10px;
}
.credit-amount-container {
  position: absolute;
  bottom: 0;
  background: #002d5f;
  color: white;
  text-align: center;
  width: 100%;
  margin-left: -16px;
  margin-bottom: 0;
  border-radius: 5px;
  padding: 2px;
  font-size: large;
}
.admin-statistics-container {
  background: white;
  height: 100%;
  border-radius: 10px;
  max-height: 36vh;
  overflow: auto;
}
.admin-notification-container {
  background: white;
  height: 100%;
  border-radius: 10px;
  padding: 20px;
  max-height: 36vh;
  overflow: auto;
}
.statistic-container-col {
  padding: 20px 40px;
}
.notification-name-email {
  overflow: hidden;
  color: #656565;
  font-feature-settings:
    "cv11" on,
    "cv01" on,
    "ss01" on;
  text-overflow: ellipsis;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 142.857% */
}

.notification-complete-time {
  color: rgba(28, 28, 28, 0.4);
  font-family: Plus Jakarta Sans;
  font-size: 11px;
  text-align: center;
  font-weight: 700;
}

.notification-row {
  cursor: pointer;
  margin-bottom: 5px;
  border-radius: 5px;
}

.notification-row:hover {
  background: lightgray;
}

.media-warning {
  padding: 0 15px;
  font-size: 15px;
  color: rgb(116, 116, 116);
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  line-height: 15.6px;
}

.dashed-border {
  border: 2px dashed darkgray;
  border-radius: 5px;
}

.report-item-change-order-button {
  color: white;
  background-color: #0d6efd;
  border-color: #0d6efd;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
}

.report-item-delete-button {
  color: white;
  background-color: red;
  border-color: red;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
}
.sadmin-page-container {
  padding: 30px 30px 0 30px;
}

.sadmin-page-container-no-top {
  padding: 0 30px 0 30px;
}

/* ###############################################################################################
###############################################################################################
###############################################################################################
###############################################################################################
############################################################################################### */
@media only screen and (max-width: 1200px) {
  .side-menu-container {
    width: 60px;
  }
  .sidemenu-button {
    margin: 25px 5px;
    padding: 6px;
  }
  .sidemenu-button-text {
    display: none;
  }

  .page-container {
    position: absolute;
    left: 60px;
    top: 60px;
  }
  .sidemenu-button-container {
    margin-left: -1px;
  }
}

/* ###############################################################################################
###############################################################################################
###############################################################################################
###############################################################################################
############################################################################################### */

@media only screen and (max-width: 500px) {
  .inner-page-container {
    padding: 5px 10px;
  }
  .app-card-container {
    margin: 20px;
    width: 295px;
    height: 340px;
    border-radius: var(--8, 8px);
    background: #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
  }
  .top-navigation-user-name {
    right: 25px;
    top: 25px;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
  }
  .top-navigation-page-name {
    font-family: Plus Jakarta Sans;
    font-size: 22px;
    line-height: 100px; /* 48px */
    padding-left: 0;
  }
  .top-navigation-logo-container {
    width: 100px;
  }
  .checkbox-image-info-text {
    font-size: 18px !important;
  }
  .polar-image-info-text {
    font-size: 14px !important;
  }

  .footer-next-button {
    height: 38px;
    width: 100%;
    font-size: 14px;
  }
  .footer-prev-button {
    width: 100%;
    height: 38px;
    font-size: 14px;
  }
  .likert-image-question-text {
    font-size: 20px;
    text-align: center;
  }
  .answer-btn:hover {
    color: #747474 !important;
    background: #fff !important;
    height: 40px !important;
    font-size: 14px !important;
  }
  .answer-btn {
    height: 40px !important;
    font-size: 14px !important;
  }

  .answer-btn-question:hover {
    color: #747474 !important;
    background: #fff !important;
    height: 200px !important;
    font-size: 14px !important;
  }
  .answer-btn-question {
    height: 200px !important;
    font-size: 14px !important;
  }
  .polar-btn-big {
    width: 44px;
    height: 44px;
    margin: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 28px;
  }

  .polar-btn-mid {
    width: 36px;
    height: 36px;
    margin: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .polar-btn-small {
    width: 28px;
    height: 28px;
    margin: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .scale-style {
    width: 60px;
    font-size: 8px;
    margin: 0 4px 21px 4px;
    padding: 3px;
    height: 25px;
  }

  .main-scope-score {
    height: 25px;
    width: 25px;
    border-radius: 25px;
  }
  .b2c-summary-score {
    height: 18px !important;
    width: 18px !important;
    border-radius: 5px !important;
    margin-top: 2px;
  }
  .sub-scope-score {
    height: 25px;
    width: 25px;
    border-radius: 25px;
  }
  .subscope-header-scope-name {
    justify-content: center;
  }
  .main-scope-score-header {
    height: 32px;
    width: 32px;
    border-radius: 32px;
    font-size: 17px;
  }
  .sub-scope-names {
    font-size: 14px;
  }

  .main-scope-name {
    font-size: 14px;
  }
  .result-page-summary-download-pdf-button-col {
    position: fixed;
    bottom: 10px;
    justify-content: center;
    z-index: 1;
  }
  .result-page-summary-result-id-col {
    height: 10px;
  }
  .result-page-result-id-h5 {
    font-size: 12px;
  }
  .subscope-name-after-scope-name {
    color: #adadad;
    font-family: Plus Jakarta Sans;
    font-size: 7px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 2px;
  }
  .scale-detail-container-top-border {
    width: 21%;
  }
  .dot-left-up {
    height: 26px;
    width: 26px;
    left: 19%;
  }
  .detail-section-scope-name-header {
    left: 29%;
    font-size: 24px;
    padding-top: 5px;
  }
  .detail-scale {
    font-size: 12px !important;
    padding: 3px !important;
    margin: 5px !important;
    height: 24px !important;
    margin-left: 0 !important;
  }
  .b2c-detail-report-header-h2 {
    background: transparent !important;
  }
  .result-page-summary-header-col {
    background: transparent !important;
  }
  .b2c-detail-report-header-container {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .main-scopes-container {
    background: transparent !important;
  }
  .summary-scale-style {
    background: white !important;
    border: 1px solid rgb(197, 197, 197);
    font-weight: 100;
    font-size: 9px;
  }
  .test-question-no-at-heder {
    position: fixed;
    font-size: 15px;
    padding-top: 3px;
  }
}
